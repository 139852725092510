// This import loads the firebase namespace.
import firebase from "firebase/app";

// These imports load individual services into the firebase namespace.
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

//2. Initialize app with the config vars
firebase.initializeApp({
  apiKey: "AIzaSyCjhncnVeTCKsgF2GJ4cOX5C8ZSfOql8Vg",
  authDomain: "questions-f5590.firebaseapp.com",
  databaseURL: "https://questions-f5590.firebaseio.com",
  projectId: "questions-f5590",
  storageBucket: "questions-f5590.appspot.com",
  messagingSenderId: "807021217384",
  appId: "1:807021217384:web:7ec8427097d6b869715ddd",
  measurementId: "G-66SZBPY6HQ",
});
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

//3. export it for use
export default firebase;
