import React, { useContext } from "react";
import { AuthContext } from "../app/Firebase/context";
import { Route, Redirect } from "react-router-dom";

export default function ProtectedRoute(props: any) {
  const { user }: any = useContext(AuthContext);
  if (user === null) {
    return <Redirect to={props.redirectTo}></Redirect>;
  } else {
    return <Route exact path={props.path} component={props.component} />;
  }
}
