import React, { useContext, useState } from "react";
import { Form, Grid, Header, Button, Segment } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import firebase from "firebase/app";
import * as firebaseui from "firebaseui";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import { AuthContext } from "../Firebase/context";

const LoginForm = () => {
  const [redirect, setRedirect] = useState("");
  const auth: any = useContext(AuthContext);

  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful
    // signInSuccessUrl: '/home',
    // Google is the only auth provider
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        clientId:
          "807021217384-tp6jbfstn9r4ia0hs1e9aoa1pc6po4nb.apps.googleusercontent.com",
      },
    ],
    // Required to enable one-tap sign-up credential helper.
    credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: function (
        authResult: any,
        redirectUrl: any
      ) {
        auth.setUser(authResult.user);
        // var updateUser = auth.firebase.functions().httpsCallable("updateUser");
        // updateUser();
        return false;
      },
    },
  };

  if (redirect) {
    return <Redirect to={redirect}></Redirect>;
  }

  if (auth.auth.currentUser) {
    return <Redirect to="/app"></Redirect>;
  }

  return (
    <>
      {/* <Menu /> */}
      <Grid
        textAlign="center"
        style={{ height: "98vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Form size="large">
            <Segment stacked>
              <Header as="h2" color="teal" textAlign="center">
                Please sign in using Google
              </Header>
              <StyledFirebaseAuth
                uiCallback={ui => ui.disableAutoSignIn()}
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
              />
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default LoginForm;
