import React, { useContext } from "react";
import { Menu, MenuItemProps } from "semantic-ui-react";
import { AuthContext } from "../Firebase/context";
import { useHistory } from "react-router-dom";

function QMenu() {
  const { auth, user, setUser }: any = useContext(AuthContext);
  const history = useHistory();

  const handleItemClick = (e: any, { name }: MenuItemProps) => {
    if (name) {
      if (name === "Sign In") {
        handleSignIn();
      } else if (name === "Sign Out") {
        handleSignOut();
      }
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      setUser(null);
      localStorage.removeItem("authUser");
    } catch (err) {
      console.log(err);
    }
    history.push("/");
  };

  function handleSignIn() {
    return history.push("/");
  }

  const welcomeMessage = `Welcome, ${user.displayName}!`;

  return (
    <div>
      <Menu pointing secondary>
        <Menu.Item>{welcomeMessage}</Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item name="Sign Out" onClick={handleItemClick} />
        </Menu.Menu>
      </Menu>
    </div>
  );
}

export default QMenu;
