import React, { useState, useContext, useEffect } from "react";
import {
  Divider,
  Message,
  Container,
  Grid,
  ButtonProps,
} from "semantic-ui-react";

import "./Home.css";
import TopMenu from "../Home/Menu";
import { AuthContext } from "../Firebase/context";
import { Question } from "../Firebase/models/question";
import QuestionCardPlaceholder from "./QuestionCardPlaceholder";
import QuestionCard from "./QuestionCard";
import AddOrRetrieveQuestion from "./AddOrRetrieveQuestion";

function Home() {
  const { firebase, user }: any = useContext(AuthContext);
  const firestore = firebase.firestore();

  let [loading, setLoading] = useState(false);
  let [questionLoading, setQuestionLoading] = useState<boolean>(false);
  let [archiving, setArchiving] = useState<boolean>(false);
  let [success, setSuccess] = useState<boolean>(false);
  let [error, setError] = useState<boolean>(false);
  let [errorMsg, setErrorMsg] = useState<string>(`Something went wrong!`);
  let [questionText, setQuestionText] = useState<string>("");
  let [lastSubmittedQuestion, setLastSubmittedQuestion] = useState<string>("");
  let [questionCount, setQuestionCount] = useState<number | null>(null);
  let [question, setQuestion] = useState<Question | null>(null);

  useEffect(() => {
    firestore
      .collection("aggregates")
      .doc("questions")
      .onSnapshot(function (querySnapshot: any) {
        setQuestionCount(querySnapshot.data().questionCount || 0);
      });
  }, [setQuestionCount, firestore]);

  return (
    <div>
      <TopMenu />
      <div style={{ padding: "2rem" }}>
        <Container>
          <Message
            icon="question circle outline"
            header="Welcome!"
            content={
              questionCount === 1
                ? `There is currently one question available.`
                : `There are currently ${
                    questionCount !== null ? questionCount : "__"
                  } questions available.`
            }
          />
          <Divider />
          {/* Pass ALL the props */}
          <AddOrRetrieveQuestion
            question={question}
            success={success}
            error={error}
            loading={loading}
            questionLoading={questionLoading}
            questionText={questionText}
            onSubmit={addQuestionClicked}
            lastSubmittedQuestion={lastSubmittedQuestion}
            setQuestionText={setQuestionText}
            getQuestion={getQuestion}
          />
          <Grid>
            <Grid.Row columns={1}>
              <Grid.Column>
                {/* TODO: make this dynamic, rendered via queue or array */}
                <Message
                  hidden={!success}
                  success
                  header="Success"
                  content={`Your question, "${lastSubmittedQuestion}" has been added!`}
                />
                <Message
                  hidden={!error}
                  error
                  header="Error"
                  content={errorMsg}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                {questionLoading && (
                  // render placeholder card while data is loading
                  <QuestionCardPlaceholder questionLoading={questionLoading} />
                )}
                {question && !questionLoading && (
                  // render question card w/ data if not loading and if question is set
                  <QuestionCard
                    question={question}
                    getQuestion={getQuestion}
                    archiveQuestion={archiveQuestion}
                    questionLoading={questionLoading}
                    archiving={archiving}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </div>
  );

  function addQuestionClicked(e: React.MouseEvent<HTMLButtonElement>) {
    // validateInput()
    setSuccess(true);
    setError(false);
    setLoading(true);

    const question: Question = {
      displayName: user.displayName,
      userEmail: user.email,
      createDate: firebase.firestore.Timestamp.now(),
      questionText: questionText,
      userPhoto: user.photoURL,
      userId: user.uid,
    };

    firestore
      .collection("questions")
      .add(question)
      .then((_: any) => {
        setSuccess(true);
        setLastSubmittedQuestion(questionText); // use docRef instead?
        setQuestionText("");
      })
      .catch(function (error: Error) {
        console.log(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function getQuestion(e: React.MouseEvent<Element>, data: ButtonProps) {
    setSuccess(false);
    setError(false);
    setQuestionLoading(true);

    try {
      const getRandomQuestion = firebase
        .functions()
        .httpsCallable("getRandomQuestion");
      const randomQuestion = await getRandomQuestion();
      const randomQuestionData = randomQuestion.data;

      // Firestore timestamp doesn't fully serialize so we need to reinitialize it
      randomQuestionData.createDate = new firebase.firestore.Timestamp(
        randomQuestionData.createDate._seconds,
        randomQuestionData.createDate._nanoseconds
      );
      setQuestion(randomQuestionData);
    } catch (err) {
      // TODO: handle error
    } finally {
      setQuestionLoading(false);
    }
  }

  function displayError() {}

  async function archiveQuestion(
    e: React.MouseEvent<Element>,
    data: ButtonProps
  ) {
    setSuccess(false);
    setError(false);
    setArchiving(true);

    try {
      // retrieve the question and parse the data
      if (!question || !question.id) throw new Error("no id to delete");
      const questionRef = await firestore
        .collection("questions")
        .doc(question.id);

      //delete doc
      await questionRef.delete();
      setQuestion(null);
    } catch (err) {
      // TODO: handle error
    } finally {
      setQuestionLoading(false);
      setArchiving(false);
    }
  }
}

export default Home;
