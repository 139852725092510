import React from 'react';
import { Form, Button, ButtonProps, Segment, Header, Icon } from 'semantic-ui-react';

import { Question } from '../Firebase/models/question';

interface AddOrRetrieveQuestionSectionProps {
  question: Question | null;
  getQuestion: (event: React.MouseEvent, data: ButtonProps) => Promise<void>;
  questionLoading: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
  questionText: string;
  lastSubmittedQuestion: string;
  onSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  setQuestionText: React.Dispatch<React.SetStateAction<string>>;
}

function AddOrRetrieveQuestionSection(props: AddOrRetrieveQuestionSectionProps) {
  return (
    <Segment.Group horizontal>
      <Segment placeholder>
        <Form success={props.success} error={props.error}>
          <Form.Input
            label="Add a question"
            placeholder="What is..."
            value={props.questionText}
            onChange={(e) => props.setQuestionText(e.target.value)}
            disabled={props.loading}
            className="fullwidth"
          />

          <Button color="teal" type="submit" onClick={props.onSubmit} loading={props.loading}>
            Add
          </Button>
        </Form>
      </Segment>
      <Segment placeholder>
        <Header icon>
          <Icon name="question circle outline" />
          Get a random question
        </Header>
        <Segment.Inline>
          <Button
            color="teal"
            onClick={props.getQuestion}
            loading={props.questionLoading}
            disabled={props.questionLoading}
          >
            Get
          </Button>
        </Segment.Inline>
      </Segment>
    </Segment.Group>
  );
}

export default AddOrRetrieveQuestionSection;
