import React, { MouseEvent } from "react";
import { Button, ButtonProps, Card, Image, Icon } from "semantic-ui-react";

import { Question } from "../Firebase/models/question";

interface QuestionCardProps {
  question: Question;
  getQuestion: (event: MouseEvent, data: ButtonProps) => Promise<void>;
  archiveQuestion: (event: MouseEvent, data: ButtonProps) => Promise<void>;
  questionLoading: boolean;
  archiving: boolean;
}

function QuestionCard(props: QuestionCardProps) {
  // use <Card fluid> for fullwidth
  return (
    <Card>
      <Card.Content>
        {props.question.userPhoto && (
          <Image floated="right" size="mini" src={props.question.userPhoto} />
        )}
        <Card.Header>{props.question.displayName}</Card.Header>
        <Card.Meta>
          Added{" "}
          {props.question.createDate.toDate().toISOString().substring(0, 10)}
        </Card.Meta>
        <Card.Description>{props.question.questionText}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button.Group>
          <Button onClick={props.getQuestion} loading={props.questionLoading}>
            <Icon name="retweet" />
            Shuffle
          </Button>
          <Button.Or />
          <Button
            onClick={props.archiveQuestion}
            color="teal"
            loading={props.archiving}
            disabled={props.archiving}
          >
            <Icon name="check" />
            Archive
          </Button>
        </Button.Group>
      </Card.Content>
    </Card>
  );
}

export default QuestionCard;
