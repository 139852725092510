//1.
import React, { useEffect, useState } from "react";
import app from "./config";
import { User } from "./models/user";
import { USER_KEY } from "../../config/constants.json";

export interface AuthProps {
  children: React.ReactNode;
}

//2.
export const AuthContext = React.createContext({});

//3.
export const AuthProvider = (AuthProps: AuthProps) => {
  const [auth] = useState<app.auth.Auth | null>(app.auth());
  const [user, setUser] = useState<app.User | null>(
    JSON.parse(localStorage.getItem(USER_KEY) || "{}")
  );

  useEffect(() => {
    // on initialize or sign in, set user in storage and update state
    app.auth().onAuthStateChanged(
      user => {
        // this case should never happen, but it satisfies typescript
        if (!user) return localStorage.removeItem(USER_KEY);

        const storedUserData = localStorage.getItem(USER_KEY);
        if (storedUserData === null) {
          updateUserInLocalStorage(user);
        } else if (storedUserData) {
          // parse existing user data from local storage
          const parsedUserData = JSON.parse(storedUserData);

          // extract the relevant User object fields
          // TODO: extract based on the interface, and not hardcoded values?
          const storedUserState = extractUserParameters(parsedUserData);

          const userDataHasChanged: boolean = userInfoHasChanged(
            storedUserState,
            user
          );

          if (userDataHasChanged) {
            updateUserInLocalStorage(user);
            // TODO: update firebase User
          }
        }
        setUser(user);
      },
      // on sign out, unset all user properties
      () => {
        setUser(null);
      }
    );
  }, []);

  return (
    <AuthContext.Provider value={{ user, auth, setUser, firebase: app }}>
      {AuthProps.children}
    </AuthContext.Provider>
  );
};

function updateUserInLocalStorage(user: firebase.default.User) {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
}

function extractUserParameters(userObject: User) {
  const { uid, photoURL, displayName, email } = userObject;
  return { uid, photoURL, displayName, email };
}

function userInfoHasChanged(user: User, savedUserData: firebase.default.User) {
  const { uid, photoURL, displayName, email } = savedUserData;
  const userDataFields = { uid, photoURL, displayName, email };
  return JSON.stringify(user) !== JSON.stringify(userDataFields);
}
