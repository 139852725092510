import React from "react";
import "semantic-ui-css/semantic.min.css";

// This import loads the firebase namespace.
import "./app/Firebase/config";

import "./App.css";
import { AuthProvider } from "./app/Firebase/context";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import SignIn from "./app/SignIn/SignIn";
import Home from "./app/Home/Home";
import ProtectedRoute from "./app/ProtectedRoute";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/" component={SignIn} />
            <ProtectedRoute path="/app" redirectTo="/" component={Home} />
            {/* <Route path="/signin" component={SignIn} /> */}
          </Switch>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
