import React from "react";
import { Button, Placeholder, Card, Icon } from "semantic-ui-react";

interface QuestionCardPlaceholderProps {
  questionLoading: boolean;
}

function QuestionCardPlaceholder(props: QuestionCardPlaceholderProps) {
  return (
    <Card>
      <Card.Content>
        <Placeholder>
          <Placeholder.Line length="short" />
          <Placeholder.Line length="short" />
          <Placeholder.Paragraph>
            <Placeholder.Line length="medium" />
          </Placeholder.Paragraph>
        </Placeholder>
      </Card.Content>
      <Card.Content extra>
        <Button.Group>
          <Button disabled={props.questionLoading}>
            <Icon name="retweet" />
            Shuffle
          </Button>
          <Button.Or />
          <Button color="teal" disabled={props.questionLoading}>
            <Icon name="check" />
            Archive
          </Button>
        </Button.Group>
      </Card.Content>
    </Card>
  );
}

export default QuestionCardPlaceholder;
